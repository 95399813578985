/**
 * Class with static helper functions for cloning
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export class CloneHelper {
    /**
     * Deep clone object
     * 
     * @param obj object
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    static deepCloneObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }
}