




























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Card, UnorderedList } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * JobAdvertisementCardList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'job-advertisement-card-form-dialog': () => import('./JobAdvertisementCardFormDialog.vue')
    }
})
export default class JobAdvertisementCardList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all cards
    @Prop({ type: Array as () => Array<Card<UnorderedList[]>> }) readonly cards: Array<Card<UnorderedList[]>> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited card
    private editedCardItem: Card<UnorderedList[]> = {
        id: -1,
        image: {
            id: -1,
            src: '',
            obj: undefined
        },
        title: '',
        content: [],
        backgroundColor: 'transparent',
        color: '#000000FF',
        button: {
            id: -1,
            routeUrl: '',
            backgroundColor: '',
            color: '#3f51b5FF',
            content: ''
        }
    };

    // Default card item values
    private defaultCardItem: Card<UnorderedList[]> = {
        id: 0,
        image: {
            id: 0,
            src: '',
            obj: undefined
        },
        title: '',
        content: [],
        backgroundColor: '#00000000',
        color: '#00000000',
        button: {
            id: 0,
            routeUrl: '',
            backgroundColor: '',
            color: '#3f51b5FF',
            content: ''
        }
    }

    // The index of the edited card (-1 = new card)
    private editedCardIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.title').toString(),
                value: 'title'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the CardFormDialog to create a new card
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addCard() {
        // get max id
        const id = (this.cards && this.cards.length >= 1) ? this.cards[this.cards.length -1].id +1 : 0;

        // FIXME spread is copying reference?
        this.editedCardItem = CloneHelper.deepCloneObject({...this.defaultCardItem, id: id });
        this.editedCardIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the CardFormDialog to edit the given card element
     * 
     * @param cardItem card item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editCard(cardItem: Card<UnorderedList[]>) {
        if (this.cards) {
            this.editedCardItem = CloneHelper.deepCloneObject(cardItem);
            this.editedCardIndex = this.cards.indexOf(cardItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given card
     * 
     * @param cardItem card item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteCard(cardItem: Card<UnorderedList[]>) {
        if (this.cards) {
            this.editedCardItem = CloneHelper.deepCloneObject(cardItem);
            this.editedCardIndex = this.cards.indexOf(cardItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedCard(formDialogMode: string, cardItemIndex: number, cardItem: Card<UnorderedList[]>) {
        if (this.cards) {
            if (formDialogMode == 'create') {
                this.cards.push(cardItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.cards[cardItemIndex], cardItem);
            }
            else if (formDialogMode == 'delete') {
                this.cards.splice(cardItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
